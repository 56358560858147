import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { browserName, browserVersion } from 'react-device-detect';
import App from './modules/App';
import './index.scss';
import UnsupportedBrowserPage from 'components/UnsupportedBrowserPage/UnsupportedBrowserPage';
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

const SUPPORTED_BROWSERS = [
  {
    name: 'Chrome',
    minVersion: 70,
  },
  {
    name: 'Edge',
    minVersion: 16,
  },
  {
    name: 'Safari',
    minVersion: 11,
  },
  {
    name: 'Mobile Safari',
    minVersion: 11,
  },
  {
    name: 'Firefox',
    minVersion: 70,
  },
  {
    name: 'Edge Chromium',
    minVersion: 70,
  },
  {
    name: 'Chrome WebView',
    minVersion: 70,
  },
  {
    name: 'WebKit',
    minVersion: 600,
  },
  {
    name: 'Samsung Browser',
    minVersion: 10,
  },
  {
    name: 'GSA',
    minVersion: 50,
  },
  {
    name: 'Google Search App',
    minVersion: 50,
  },
  {
    name: 'UCBrowser',
    minVersion: 11,
  },
  {
    name: 'Android Browser',
    minVersion: 4,
  },
];

const isSupportedBrowser = () =>
  SUPPORTED_BROWSERS.find((browser) => browser.name === browserName && browser.minVersion <= browserVersion);

if (isSupportedBrowser()) {
  ReactDOM.render(<App />, document.getElementById('root'));
} else {
  ReactDOM.render(<UnsupportedBrowserPage />, document.getElementById('root'));
}
