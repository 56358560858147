import React from 'react';
import NotFoundLogo from 'components/NotFoundLogo/NotFoundLogo';
import './UnsupportedBrowserPage.scss';

const UnsupportedBrowserPage = () => {
  return (
    <div className={'unsupported_browser_page'}>
      <div className={'unsupported_browser_page_icon'}>
        <NotFoundLogo />
      </div>
      <div className={'unsupported_browser_page_text'}>
        Our website platform works best in all modern browsers including the latest versions of Chrome, Firefox, Edge,
        and Safari. Please use one of those browsers.
      </div>
    </div>
  );
};

export default UnsupportedBrowserPage;
