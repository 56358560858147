import React, { useState, useEffect, memo } from 'react';
import { Grid } from '@material-ui/core';
import styles from './CheckoutOrder.module.scss';
import Section from 'components/Section/Section';
import classNames from 'classnames/bind';
import { TextButton, PrimaryButton } from 'components/Button/Button';
import CheckoutOrderingFrom from './OrderingFrom/CheckoutOrderingFrom';
import OrderInformation from './OrderInformation/OrderInformation';
import CouponCode from './CouponCode/CouponCode';
import AddTip from './AddTip/AddTip';
import Summary from './Summary/Summary';
import ContactInformation from './ContactInformation/ContactInformation';
import useQueryUrlParamsDispatch from 'hooks/useQueryUrlParamsDispatch';
import { useAppDispatchContext, useAppStateContext } from 'stateProviders/useAppStateContext';
import Spin from 'components/Spin/Spin';
import Alert from 'components/Alert/Alert';
import AvailableRewards from './AvailableRewards/AvailableRewards';
import ChangeLocationLink from 'components/ChangeLocationLink/ChangeLocationLink';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import { ACTION_TYPES } from '../../../stateProviders/appStateProvider';
import showToast from 'components/Toast/ShowToast';
import useSubmitOrder from './PaymentMethod/hooks/useSubmitOrder';
import { useParams } from 'react-router';
import useRetrieveBillingUserAccounts from './PaymentMethod/SavedCreditCard/hooks/useRetrieveBillingUserAccounts';
import GiftCard from './PaymentMethod/GiftCard/GiftCard';
import { useCallback } from 'react';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { HANDOFF_MODE_ENUM, getHandoffModeName } from '../../HandoffModeModal/HandoffModeNew';
import OrderTime from './OrderTime/OrderTime';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import StoreIcon from '@material-ui/icons/Store';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow_right.svg';
import { ReactComponent as SmallLogo } from '../../../assets/small-logo.svg';
import HandoffInfo from 'components/HandoffInfo/HandoffInfo';
import HandoffModeNew from 'modules/HandoffModeModal/HandoffModeNew';
import useAddTipsFetch from './AddTip/hooks/useAddTipsFetch';

const CONTACT_NUMBER_ERROR_MSG =
  'You should enter contact phone number that we can call you if there are any issues with your order';
const DELIVERY_ADDRESS_ERROR_MSG = 'You should enter a delivery address';
const DELIVERY_TIME_ERROR_MSG = 'You should enter delivery time';
const CURBSIDE_ERROR_MSG = 'You should enter all required fields for curbside pickup';
const PICKUP_TIME_ERROR_MSG = 'You should enter pick-up time';

const CheckoutOrder = () => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  const [isTouched, setIsTouched] = useState(false);
  const [contactPhoneNumber, setContactPhoneNumber] = useState();
  const [errorsNewPaymentCard, setErrorsNewPaymentCard] = useState(null);
  const [isDefaultOrderTimeSetting, setIsDefaultOrderTimeSetting] = useState(true);

  const [orderMode, setOrderMode] = useState('curbside');

  const { restaurantId } = useParams();
  const {
    authenticated,
    groupOrder,
    basket: { data: basketData, loading: basketLoading, error: basketError },
    paymentData: { isFetchingTokens, isGiftCardOnlyPayment, isUseSavedCard, isUseCardTouched, isCash },
    restaurant: { data: restaurantData },
    user: { data: userData },
  } = useAppStateContext();
  // if NaN - there is no request
  const [resetTips, setResetTips] = useState(isCash ? 0 : NaN);
  useAddTipsFetch(resetTips, true);
  useRetrieveBillingUserAccounts();
  const groupOrderExist = !(groupOrder && groupOrder.data === null && !groupOrder.error);
  const [isOrderTimeSectionValid, setIsOrderTimeSectionValid] = useState(false);
  const [{ loading }, submit, resetState] = useSubmitOrder();
  const guestUser = JSON.parse(window.sessionStorage.getItem('guestData'));
  const supportsTip = restaurantData && restaurantData.supportstip;
  const supportsCoupons = restaurantData && restaurantData.supportscoupons;
  const setHistoryPush = useQueryUrlParamsDispatch();
  const appDispatch = useAppDispatchContext();
  const user = userData || guestUser;
  const userStatePhoneNumber = user && user['mobilePhone'];
  const basketErrorMessage =
    (basketError &&
      (basketError.errorMessage || 'Something went wrong... Could not load summary. Please try again later!')) ||
    '';

  useEffect(() => {
    if (!contactPhoneNumber && (userData || guestUser)) {
      const phoneNumber = authenticated ? userData.mobilePhone : guestUser.mobilePhone;
      setContactPhoneNumber(phoneNumber);
    }
  }, [authenticated, userData, guestUser, contactPhoneNumber]);

  const changeOrderMode = (mode) => {
    setOrderMode(mode);
    console.log(orderMode);
  };

  const changeIsCash = useCallback(
    (isCash) => {
      setResetTips(isCash ? 0 : NaN);
      appDispatch({
        payload: isCash,
        type: ACTION_TYPES.setIsCash,
      });
    },
    [appDispatch]
  );

  const onErrorNewCard = (errors) => {
    setErrorsNewPaymentCard(errors);
  };
  const errorHandler = useCallback(() => {
    let errors = [];
    if (!contactPhoneNumber) {
      errors.push(CONTACT_NUMBER_ERROR_MSG);
    }
    if (
      !isCash &&
      !isUseSavedCard &&
      errorsNewPaymentCard &&
      errorsNewPaymentCard.length > 0 &&
      !isGiftCardOnlyPayment
    ) {
      const errorsMsg = errorsNewPaymentCard.map((error) => error.msg);
      errors = errors.concat(errorsMsg);
    }
    return errors;
  }, [contactPhoneNumber, errorsNewPaymentCard, isUseSavedCard, isGiftCardOnlyPayment, isCash]);
  const submitOrderForm = () => {
    debugger;
    resetState();
    setIsTouched(true);
    const emptyCustomfields =
      basketData && basketData.customfields && basketData.customfields.find((item) => !item.value);
    const isCurbsideModeSelected = basketData && basketData.deliverymode === HANDOFF_MODE_ENUM.CURBSIDE;

    if (!isCash) {
      appDispatch({
        payload: true,
        type: ACTION_TYPES.setIsUseCardTouched,
      });
    }

    const errors = errorHandler();
    if (errors.length > 0) {
      errors.map((error) => showToast('error', error));
      return;
    }
    if (!isOrderTimeSectionValid) {
      showToast('error', 'Please select order time');
      return;
    }
    if (isCurbsideModeSelected && emptyCustomfields) {
      showToast('error', CURBSIDE_ERROR_MSG);
      return;
    }
    submit(contactPhoneNumber);
  };

  useEffect(() => {
    if (
      isCash &&
      basketData &&
      (basketData.deliverymode === HANDOFF_MODE_ENUM.DELIVERY || basketData.deliverymode === HANDOFF_MODE_ENUM.DISPATCH)
    ) {
      changeIsCash(false);
    }
  }, [isCash, changeIsCash, basketData]);

  useEffect(() => {
    if (basketErrorMessage) {
      showToast('error', basketErrorMessage);
    }
  }, [basketErrorMessage]);

  const handleEditOrder = () => {
    appDispatch({ type: ACTION_TYPES.cleanUpPaymentData });
    appDispatch({ type: ACTION_TYPES.cleanUpGiftCard });
    setHistoryPush(
      {
        openBasket: true,
      },
      `/${restaurantId}/menu`
    );
  };
  const onSubmitPhoneNumber = (phoneNumber) => setContactPhoneNumber(phoneNumber);
  const onClickPayRestaurantTab = () => {
    appDispatch({
      type: ACTION_TYPES.clearPaymentData,
    });
    changeIsCash(true);
  };
  const isShowGlassWindow = loading || isFetchingTokens;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <PrimaryHeader size="extra" title="Checkout" />
      </div>
      <div className={styles.body}>
        <Grid container spacing={isMobileSize ? 0 : 5}>
          <Grid item md={12} lg={6} xs={12} sm={12}>
            <Section
              sectionHr
              title={'Ordering from'}
              className={styles.ordering_from}
              description={groupOrderExist ? null : <ChangeLocationLink className={styles.link} />}
            >
              <CheckoutOrderingFrom />
            </Section>
            <HandoffModeNew changeOrderMode={changeOrderMode} />
            {(basketLoading || basketError) && !basketData && (
              <Section title="Delivery Time">
                {basketLoading && <Spin spinning={basketLoading} />}
                {basketError && <Alert message={basketErrorMessage} />}
              </Section>
            )}
            {basketData && restaurantData && (
              <Section sectionHr title={orderMode == 'curbside' ? 'Pick-up Time' : 'Delivery Time'}>
                <OrderTime
                  isTouched={isTouched}
                  isDelivery={
                    basketData.deliverymode === HANDOFF_MODE_ENUM.DELIVERY ||
                    basketData.deliverymode === HANDOFF_MODE_ENUM.DISPATCH
                  }
                  setIsOrderTimeSectionValid={setIsOrderTimeSectionValid}
                  isMobileSize={isMobileSize}
                  restaurant={restaurantData}
                  basket={basketData}
                  setIsDefaultOrderTimeSetting={setIsDefaultOrderTimeSetting}
                />
              </Section>
            )}
            <Section
              className={styles.section}
              title="Your order"
              sectionHr
              description={
                <TextButton
                  disabled={basketLoading || Boolean(basketError)}
                  className={styles.link}
                  onClick={handleEditOrder}
                >
                  Edit order
                </TextButton>
              }
            >
              {basketLoading && !basketData && <Spin spinning={basketLoading} />}
              {basketErrorMessage && <Alert message={basketErrorMessage} />}
              {basketData && <OrderInformation products={basketData.products} />}
            </Section>
            {!userStatePhoneNumber && <ContactInformation onSubmit={onSubmitPhoneNumber} isTouched={isTouched} />}
            {authenticated ? (
              <Section
                className={styles.section}
                title={[<SmallLogo key="rewards-logo" className={styles.section_rewards_icon} />, 'REWARDS']}
                sectionHr
              >
                <AvailableRewards />
              </Section>
            ) : null}
          </Grid>
          <Grid item md={12} lg={6} xs={12} sm={12}>
            <Section className={styles.section} sectionHr title="Payment Method">
              {basketErrorMessage && <Alert message={basketErrorMessage} />}
              {basketData && (
                <>
                  {basketData.deliverymode === HANDOFF_MODE_ENUM.PICKUP ||
                    (basketData.deliverymode === HANDOFF_MODE_ENUM.CURBSIDE && (
                      <>
                        <div className={styles.pay_restaurant_description}>
                          To use a Military Discount, Bonus Cards, Kids Eat Free offer or other coupons, select “Pay at the Restaurant”.
                        </div>

                        <div className={styles.pay_options_wrapper}>
                          {basketLoading && <Spin className={styles.pay_options_spinner} spinning={basketLoading} />}
                          <ButtonGroup
                            className={classNames(styles.pay_options_btns, {
                              [styles.pay_options_btns_hidden]: basketLoading,
                            })}
                          >
                            <PrimaryButton
                              className={classNames(styles.left, {
                                active: !isCash,
                                [styles.not_active_text]: isCash,
                                [styles.active_text]: !isCash,
                              })}
                              disabled={basketLoading}
                              onClick={() => {
                                changeIsCash(false);
                              }}
                              endIcon={<DesktopWindowsIcon className={styles.icon} />}
                            >
                              PAY ONLINE
                            </PrimaryButton>
                            <PrimaryButton
                              className={classNames(styles.right, {
                                active: isCash,
                                [styles.not_active_text]: !isCash,
                                [styles.active_text]: isCash,
                              })}
                              disabled={basketLoading}
                              onClick={onClickPayRestaurantTab}
                              endIcon={<StoreIcon className={styles.icon} />}
                            >
                              PAY AT THE RESTAURANT
                            </PrimaryButton>
                          </ButtonGroup>
                        </div>
                      </>
                    ))}
                  {
                    <div
                      className={classNames({
                        [styles.hidden_payment_method]: isCash,
                      })}
                    >
                      <PaymentMethod
                        isTouched={isGiftCardOnlyPayment ? false : isUseCardTouched}
                        errorsNewPaymentCard={errorsNewPaymentCard}
                        onErrorNewCard={onErrorNewCard}
                      />
                      <GiftCard />
                    </div>
                  }
                  {basketData && supportsCoupons && (
                    <Section className={styles.section} sectionHr title="Have a coupon code?">
                      <CouponCode basket={basketData} />
                    </Section>
                  )}
                  {basketData && supportsTip && !isCash && (
                    <Section className={styles.section} sectionHr title="Add Tip">
                      <AddTip
                        currTips={basketData && basketData.tip}
                        defaultTips={restaurantData && restaurantData.suggestedtippercentage}
                        subtotal={basketData && basketData.subtotal}
                      />
                    </Section>
                  )}
                  <Section className={styles.section} sectionHr title={'Summary'}>
                    {basketLoading && !loading && <Spin spinning={basketLoading} />}
                    {basketErrorMessage && <Alert message={basketErrorMessage} />}
                    {basketData && !basketLoading && <Summary basket={basketData} />}
                  </Section>
                </>
              )}
            </Section>
          </Grid>
        </Grid>
      </div>
      <div className={styles.footer}>
        <PrimaryButton
          disabled={basketLoading || Boolean(basketError) || isFetchingTokens}
          onClick={submitOrderForm}
          className={styles.footer_btn}
          endIcon={<ArrowRight />}
          size={'large'}
        >
          PLACE ORDER
        </PrimaryButton>
      </div>
      {isShowGlassWindow && (
        <div className={styles.glass}>
          <Spin className={styles.spinner} spinning={isShowGlassWindow} />
        </div>
      )}
    </div>
  );
};
const MemoizedCheckoutOrder = memo(CheckoutOrder);
export default MemoizedCheckoutOrder;
